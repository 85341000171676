
import {useNavigate} from "react-router"
import "../css/home.css";
const Home=()=>{
    const navigate =useNavigate();
    const goShell=()=>{
        navigate("/jjdyhx")  
    }
    return <div className="home">
        <div className="home_button" onClick={goShell}>嘉嘉的烟花秀</div>
    </div>
}

export default Home