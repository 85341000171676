
import { BrowserRouter , Route, Navigate, Routes } from 'react-router-dom'
import Home from '../page/Home'
import Shell from '../page/Shell'

const Router =()=>{

    return <BrowserRouter>
        <Routes >
            <Route path='/' element={<Home />}></Route>
            <Route path='/jjdyhx' element={<Shell/>}></Route>
            <Route path='/*' element={<Navigate to="/" />}></Route>
        </Routes>

    </BrowserRouter>    
}

export default Router