
import { useEffect } from "react"
import "../css/shell.css"

const Shell = () => {

    useEffect(() => {
        import("../utils/shell")
    }, [])

    return <div className="container">
        <div className="loading-init">
            <div className="loading-init__header">等一会呦</div>
            <div className="loading-init__status"></div>
        </div>
        <div className="stage-container remove">
            <div className="canvas-container">
                <canvas id="trails-canvas"></canvas>
                <canvas id="main-canvas"></canvas>
            </div>
            <div className="controls">
                <div className="btn pause-btn"><svg fill="white" width="24" height="24">
                    <use href="#icon-pause" xlinkHref="#icon-pause"></use>
                </svg></div>
                <div className="btn sound-btn"><svg fill="white" width="24" height="24">
                    <use href="#icon-sound-off" xlinkHref="#icon-sound-off"></use>
                </svg></div>
                <div className="btn settings-btn"><svg fill="white" width="24" height="24">
                    <use href="#icon-settings" xlinkHref="#icon-settings"></use>
                </svg></div>
            </div>
            <div className="menu hide">
                <div className="menu__inner-wrap">
                    <div className="btn btn--bright close-menu-btn"><svg fill="white" width="24" height="24">
                        <use href="#icon-close" xlinkHref="#icon-close"></use>
                    </svg></div>
                    <div className="menu__header">设置</div>
                    <form>
                        <div className="form-option form-option--select"><label className="shell-type-label">烟火类型</label><select
                            className="shell-type"></select></div>
                        <div className="form-option form-option--select"><label className="shell-size-label">烟火大小</label><select
                            className="shell-size"></select></div>
                        <div className="form-option form-option--select"><label className="quality-ui-label">烟火质量</label><select
                            className="quality-ui"></select></div>
                        <div className="form-option form-option--select"><label
                            className="sky-lighting-label">天空照明</label><select className="sky-lighting"></select></div>
                        <div className="form-option form-option--select"><label
                            className="scaleFactor-label">缩放</label><select className="scaleFactor"></select></div>
                        <div className="form-option form-option--checkbox"><label
                            className="auto-launch-label">自动燃放</label><input className="auto-launch" type="checkbox" />
                        </div>
                        <div className="form-option form-option--checkbox form-option--finale-mode"><label
                            className="finale-mode-label">自动大量</label><input className="finale-mode" type="checkbox" />
                        </div>
                        <div className="form-option form-option--checkbox"><label
                            className="hide-controls-label">隐藏控制器</label><input className="hide-controls" type="checkbox" />
                        </div>
                        <div className="form-option form-option--checkbox form-option--fullscreen"><label
                            className="fullscreen-label">全屏</label><input className="fullscreen" type="checkbox" />
                        </div>
                        <div className="form-option form-option--checkbox"><label
                            className="long-exposure-label">留痕</label><input className="long-exposure" type="checkbox" />
                        </div>
                    </form>
                    <div className="credits"></div>
                </div>
            </div>
        </div>
        <div className="help-modal">
            <div className="help-modal__overlay"></div>
            <div className="help-modal__dialog">
                <div className="help-modal__header"></div>
                <div className="help-modal__body"></div><button type="button" className="help-modal__close-btn">关闭</button>
            </div>
        </div>
    </div>
}

export default Shell